<template>
  <div class="modal-wrap">
  <h1 class="tit-h1 d-flex">
    기업신규계정생성
    <div class="ml-auto align-self-center">
      <v-icon x-small class="svg-close20" @click="[$emit('hide'),init()]"></v-icon>
    </div>
  </h1>
  <div class="modal-box">
    <!-- 신규 기업 등록 다이얼로그 시작 -->
    <div class="box-ct">
      <div class="table-form">
        <h2 class="tit-h2">
          이용서비스정보
        </h2>
        <table class="mt-3">
          <colgroup>
            <col width="127px" />
            <col width="529px" />
            <col width="127px" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">이용서비스</v-badge>
              </th>
              <td>
                <div class="form-inp full">
                  <v-select
                    :items="USE_SRV"
                    item-value="CD"
                    item-text="CD_NM"
                    outlined
                    hide-details
                    placeholder="선택하세요"
                    v-model="USE_NEWSRV"
                  >
                  </v-select>
                </div>
              </td>
              <th scope="row">
                <v-badge dot color="#EF9191">서비스유지여부</v-badge>
              </th>
              <td>
                <div class="form-inp full">
                  <v-select
                    :items="SRV_YN"
                    item-value="CD"
                    item-text="CD_NM"
                    outlined
                    hide-details
                    placeholder="선택하세요"
                    v-model="SRV_NEWYN"
                  >
                  </v-select>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">서비스시작일시</v-badge>
              </th>
              <td>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  content-class="calendar-modal dateTime"
                  color="#FFF"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormatted2"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      hide-details
                      class="form-inp icon-calendar"
                      append-icon="svg-calendar"
                    ></v-text-field>
                  </template>
                  <div class="d-flex">
                    <v-date-picker
                        v-model="date2"
                        dark
                        color="#F7AFAE"
                        show-current="false"
                        class="borderR16"
                        :first-day-of-week="1"
                        locale="ko-KR"
                        :day-format="mixin_getDate"
                      ></v-date-picker>
                      <v-time-picker
                        v-model="timeStep2"
                        :allowed-minutes="allowedStep2"
                        color="#F7AFAE"
                        format="24hr"
                        class="borderR16"
                        @click:minute="$refs.menu2.save(time)"
                      ></v-time-picker>
                    </div>
                </v-menu>
              </td>
              <th scope="row">
                <v-badge dot color="#EF9191">서비스종료일시</v-badge>
              </th>
              <td>
                <v-menu
                  ref="menu4"
                  v-model="menu4"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  content-class="calendar-modal dateTimel"
                  color="#FFF"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormatted4"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      hide-details
                      class="form-inp full icon-calendar"
                      append-icon="svg-calendar"
                    ></v-text-field>
                  </template>
                  <div class="d-flex align-center">
                    <v-date-picker
                        v-model="date4"
                        dark
                        color="#F7AFAE"
                        show-current="false"
                        class="borderR16"
                        :first-day-of-week="1"
                        locale="ko-KR"
                        :day-format="mixin_getDate"
                      ></v-date-picker>
                      <v-time-picker
                        v-model="timeStep4"
                        :allowed-minutes="allowedStep4"
                        color="#F7AFAE"
                        format="24hr"
                        class="borderR16"
                        @click:minute="$refs.menu4.save(time)"
                      ></v-time-picker>
                    </div>
                </v-menu>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">계정생성제한건수</v-badge>
              </th>
              <td>
                <v-text-field
                  class="form-inp full"
                  name="name" value="계정생성제한건수" id="id" outlined hide-details=""
                  v-model="LIMIT_NEWMAKE">
                </v-text-field>
              </td>
              <th scope="row">
                <v-badge dot color="#EF9191">전화번호</v-badge>
              </th>
              <td>
                <v-text-field
                  class="form-inp full"
                  name="name" value="전화번호" id="id" outlined hide-details=""
                  v-model="PHONE_NEWNUM">
                </v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
        <h2 class="tit-h2 mt-3">
          기업기본정보
        </h2>
        <table class="mt-3">
          <colgroup>
            <col width="127px" />
            <col width="529px" />
            <col width="127px" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">기업명</v-badge>
              </th>
              <td>
                <v-text-field
                  class="form-inp full"
                  name="name" value="기업명" id="id" outlined hide-details=""
                  v-model="COMPANY_NEWNM">
                </v-text-field>
              </td>
              <th scope="row">
                <v-badge dot color="#EF9191">사업자등록번호</v-badge>
              </th>
              <td>
                <v-text-field
                  class="form-inp full"
                  name="name" value="사업자등록번호" id="id" outlined hide-details=""
                  v-model="BUSI_NEWREGIST">
                </v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <div class="text-right mt-3">
            <h3>약관을 동의한것으로 간주합니다.</h3>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point mt-3" v-on:click="showAlertReg()"> 저장 </v-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- 신규 기업 등록 다이얼로그 -->
  </div>
  </div>
</template>

<script>
import api from '../../store/apiUtil.js';
import DialogM810706P01P01 from "./M810706P01P01.vue";
import { mixin } from "@/mixin/mixin.js";

export default {
  name: "MENU_M810706P02", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  props: {
  },
  computed: {
  },
  components: {
  },
  data() {
    return {
      menu2: false,
      menu4: false,
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date4: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      timeStep2: '10:10',
      timeStep4: '10:10',
      gridDataHeaders2: [
        {
          text: 'No',
          align: 'center',
          value: 'index',
          width: '50px',
          sortable: false,
        },
        {text:"고객사ID", value: 'ASP_CUST_KEY', align: 'center', sortable: false, },
        {text:"고객사명", value: 'COMPANY_NM', align: 'center', sortable: false, },
        {text:"전화번호", value: 'PHONE_NUM', align: 'center', sortable: false, },
        {text:"큐번호", value: 'QUEUE_NUM', align: 'center', sortable: false, },
        {text:"등록자ID", value: 'REGR_ID', align: 'center', sortable: false, },
        {text:"최근수정일시", value: 'REG_DTTM', align: 'center', sortable: false, },
        ],
      gridDataText2: [],
      page2: 1,
      pageCount2: 0,
      itemsPerPage2: 5,
      totalVisible2: 10,

      USE_SRV:[
        {
          CD: '',
          CD_NM: '선택'
        },
        {
          CD: 'TRIAL',
          CD_NM: '테스터'
        },
        {
          CD: 'STANDARD',
          CD_NM: '스탠다드'
        },
        {
          CD: 'ENTERPRISE',
          CD_NM: '엔터프라이즈'
        },
        {
          CD: 'FRIENDS',
          CD_NM: '프렌즈'
        },
      ],

      SRV_YN:[
        {
          CD: '',
          CD_NM: '선택'
        },
        {
          CD: 'Y',
          CD_NM: '사용'
        },
        {
          CD: 'N',
          CD_NM: '미사용'
        },
      ],


      USE_NEWSRV:'',
      SRV_NEWYN:'',

      LIMIT_NEWMAKE: '',
      PHONE_NEWNUM: '',
      COMPANY_NEWNM:'',
      BUSI_NEWREGIST:'',

      CUST_EXIST:'',
    };
  },

  created() {
  },

  methods: {
    formatDate (date) {
    if (!date) return null

    const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },
    allowedStep2: (m) => m % 10 === 0,
    allowedStep4: (m) => m % 10 === 0,

    init(){
      this.USE_NEWSRV='';
      this.SRV_NEWYN='';

      this.LIMIT_NEWMAKE= '';
      this.PHONE_NEWNUM= '';
      this.COMPANY_NEWNM='';
      this.BUSI_NEWREGIST='';

      this.CUST_EXIST='';
    },

    async btnNewAsp(){
      await api.post("api/setting/system/entrprs-acnt-manage/ctmmny-regist-popup/exist",   //api url입력
      {
        BUSI_REGIST: this.BUSI_NEWREGIST,
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "setting.system.entrprs-acnt-manage.ctmmny-regist-popup",
        "METHOD" : "exist",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        this.CUST_EXIST = response.data.DATA[0].CNT;
      })
      .catch((err) => {
        alert(err);
      })
      if (this.CUST_EXIST == '1'){
        this.showAlert("해당 기업은 이미 등록되어있습니다.");
        return;
      }
      if (this.USE_NEWSRV == "" || this.USE_NEWSRV == undefined) {
        this.showAlert("이용서비스를 선택해주세요.");
        return;
      }
      if (this.SRV_NEWYN == "" || this.SRV_NEWYN == undefined) {
        this.showAlert("서비스 유지여부를 선택해주세요.");
        return;
      }
      if (this.LIMIT_NEWMAKE == "" || this.LIMIT_NEWMAKE == undefined) {
        this.showAlert("계정 생성 제한건수를 확인해주세요.");
        return;
      }
      if (this.PHONE_NEWNUM == "" || this.PHONE_NEWNUM == undefined) {
        this.showAlert("전화번호를 입력해주세요.");
        return;
      }
      if (this.COMPANY_NEWNM == "" || this.COMPANY_NEWNM == undefined) {
        this.showAlert("기업명을 입력해주세요.");
        return;
      }
      if (this.BUSI_NEWREGIST == "" || this.BUSI_NEWREGIST == undefined) {
        this.showAlert("사업자 등록번호를 입력해주세요.");
        return;
      }
      let DT1 = this.date2;
      let DT1_Array = DT1.split("-");
      var YEAR1 = DT1_Array[0];
      var MONTH1 = DT1_Array[0] + DT1_Array[1];
      var ST_DATE = DT1_Array[0] + DT1_Array[1] + DT1_Array[2];

      let TM1 = this.timeStep2;
      let TM1_Array = TM1.split(":");
      var ST_TM = TM1_Array[0] + TM1_Array[1];

      let DT2 = this.date4;
      let DT2_Array = DT2.split("-");
      var MONTH2 = DT2_Array[0] + DT2_Array[1];
      var END_DATE = DT2_Array[0] + DT2_Array[1] + DT2_Array[2];

      let TM2 = this.timeStep4;
      let TM2_Array = TM2.split(":");
      var END_TM = TM2_Array[0] + TM2_Array[1];

      api.post("api/setting/system/entrprs-acnt-manage/ctmmny-regist-popup/regist",   //api url입력
      {
        PRODUCT_CD: this.USE_NEWSRV,
        SERV_START_DTTM: ST_DATE+ST_TM,
        SERV_START_DATE: ST_DATE,
        SERV_START_TIME: ST_TM,
        SERV_END_DTTM: END_DATE+END_TM,
        SERV_END_DATE: END_DATE,
        SERV_END_TIME: END_TM,
        ACCOUNT_CREATION_LIMIT: this.LIMIT_NEWMAKE,
        PHONE_NUM: this.PHONE_NEWNUM,
        COMPANY_NM: this.COMPANY_NEWNM,
        BUSI_REGIST: this.BUSI_NEWREGIST,
        SERV_KEEP_YN: this.SRV_NEWYN,
        REGR_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        AMDR_DEPT_CD: 'x',
        AMDR_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        REG_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        CUST_AGREE_YN: 'Y',
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "setting.system.entrprs-acnt-manage.ctmmny-regist-popup",
        "METHOD" : "regist",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        this.init();
        this.$emit("Qsave","selectList");
      })
      .catch((err) => {
        alert(err);
      })

    },

    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },

    showAlertReg(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '신규기업을 등록하시겠습니까?',
        iconClass: 'svg-chk-lg',
        type: 'confirm',
        callYes: this.callYes,
        callNo: this.callNo,
      })
    },

    callYes() {
      this.$store.commit("alertStore/openAlertDialog", {
      alertDialogToggle: false,
      });
      this.btnNewAsp();
    },

    callNo(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    success(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '정상처리 되었습니다.',
        iconClass: 'svg-done-lg',
        type: 'default'
      })
    },

    fail(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.',
        iconClass: 'svg-error-lg',
        type: 'default'
      })
    },
  },
  computed: {
    computedDateFormatted2(){
      return `${this.date2} ${this.timeStep2}`
    },
    computedDateFormatted4(){
      return `${this.date4} ${this.timeStep4}`
    },
  },
  watch: {
    gridNewDataText(){
      this.ASP_QCUST_KEY = this.gridNewDataText[0].ASP_CUST_KEY;
      this.COMPANY_QNM = this.gridNewDataText[0].COMPANY_NM;
      this.PHONE_QNUM = this.gridNewDataText[0].PHONE_NUM;
      this.selectQList();
    },
  }
};
</script>

<style></style>

<template>
  <div class="modal-wrap">
  <h1 class="tit-h1 d-flex">
    큐등록
    <div class="ml-auto align-self-center">
      <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
    </div>
  </h1>
  <div class="modal-box">
    <div class="text-right">
      <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnPopupNewQ')" outlined class="btn-default ml-2" v-on:click="showDialog('M810706P01P01')">신규</v-btn>
      <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined class="btn-etc ml-2" v-on:click="showAlertDel">삭제</v-btn>
    </div>
  <div class="box-ct mt-3">
    <v-data-table
      dense
      height="550px"
      :headers="gridDataHeaders2"
      :items="gridDataText2"
      :items-per-page="30"
      item-key="index"
      :page.sync="page2"
      hide-default-footer
      class="grid-default"
      calculate-widths
      @page-count="pageCount2 = $event"
      @click:row="rowClick2"
      single-select
      no-data-text="검색된 큐가 없습니다."
      :item-class="isActiveRow"
      fixed-header
    >
    </v-data-table>
    <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page2"
            :length="pageCount2"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
        <p class="caption text-right">
          <strong class="clr-error">{{ gridDataText2.length }}</strong
          >건의 큐가 검색되었습니다.
        </p>
    </div>
    <!-- 큐등록 모달창 -->
    <div class="">
      <v-dialog
        max-width="1400"
        max-height="1000"
        v-model="DialogM810706P01P01"
        content-class="square-modal min-auto"
      >
        <dialog-M810706P01P01
            headerTitle="큐등록"
            @hide="hideDialog('M810706P01P01')"
            :Qdata="Qdata"
            @input="Qdata = $event"
            @Qsave="selectQList(),hideDialog('M810706P01P01')"
        >
        </dialog-M810706P01P01>
        <template v-slot:body>

        </template>
      </v-dialog>
    </div>
    <!-- 큐등록 모달창 -->
  </div>
  </div>
</template>

<script>
import api from '../../store/apiUtil.js';
import DialogM810706P01P01 from "./M810706P01P01.vue";
import { mixin } from "@/mixin/mixin.js";

export default {
  name: "MENU_M810706P01", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  props: {
    gridNewDataText: {
      type: Object,
      value:{}
    },
  },
  computed: {
  },
  components: {
    DialogM810706P01P01,
  },
  data() {
    return {
      DialogM810706P01P01: false,
      gridDataHeaders2: [
        {
          text: 'No',
          align: 'center',
          value: 'index',
          width: '50px',
          sortable: false,
        },
        {text:"고객사ID", value: 'ASP_CUST_KEY', align: 'center', sortable: false, },
        {text:"고객사명", value: 'COMPANY_NM', align: 'center', sortable: false, },
        {text:"전화번호", value: 'PHONE_NUM', align: 'center', sortable: false, },
        {text:"큐번호", value: 'QUEUE_NUM', align: 'center', sortable: false, },
        {text:"등록자ID", value: 'REGR_ID', align: 'center', sortable: false, },
        {text:"최근수정일시", value: 'REG_DTTM', align: 'center', sortable: false, },
        ],
      gridDataText2: [],
      page2: 1,
      pageCount2: 0,
      itemsPerPage2: 5,

      Qdata:{},

      QUEUE_QNUM:'',

      ASP_QCUST_KEY: this.gridNewDataText.ASP_CUST_INFO,
      COMPANY_QNM: this.gridNewDataText.COMPANY_NM,
      PHONE_QNUM: this.gridNewDataText.PHONE_NUM,

      selectedRows:[],
    };
  },

  created() {
    this.selectQList();
  },

  methods: {
    selectQList(){
      api.post("api/setting/system/entrprs-acnt-manage/queue/selectlist",   //api url입력
      {
        ASP_CUST_INFO: this.ASP_QCUST_KEY,
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "setting.system.entrprs-acnt-manage.queue",
        "METHOD" : "selectlist",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        console.log(response.data.DATA);
        this.gridDataText2 = response.data.DATA;
        for(var i=0; i<this.gridDataText2.length;i++){
          this.gridDataText2[i]["index"]= i+1;
        }
        this.page=1;

      })
      .catch((err) => {
        this.fail();
      })
    },

    //Table row 클릭이벤트
    rowClick2(item, row) {
      this.gridSelected = item;

      this.QUEUE_QNUM = item.QUEUE_NUM;
    },

    isActiveRow(item){
      const activeClass = item.QUEUE_NUM === this.QUEUE_QNUM ? 'active' : '';
      return activeClass;
    },

    deleteQ(){
      api.post("api/setting/system/entrprs-acnt-manage/queue/delete",   //api url입력
      {
        ASP_CUST_INFO: this.ASP_QCUST_KEY,
        QUEUE_NUM: this.QUEUE_QNUM,
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "setting.system.entrprs-acnt-manage.queue",
        "METHOD" : "delete",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        this.selectQList();
        this.success();
      })
      .catch((err) => {
        this.fail();
      })
    },

    showDialog(menu) {
      console.log("COMPANY_QNM"+ this.COMPANY_QNM + "PHONE_QNUM"+ this.PHONE_QNUM +"ASP_QCUST_KEY" +this.ASP_QCUST_KEY);
      this.Qdata.COMPANY_NEWNM = this.COMPANY_QNM;
      this.Qdata.PHONE_NEWNUM = this.PHONE_QNUM;
      this.Qdata.ASP_NEWCUST_KEY = this.ASP_QCUST_KEY;
      console.log(this.Qdata);
      console.log(this.aaaaaaa);
      this[`Dialog${menu}`] = true;
    },
    hideDialog(menu) {
      this[`Dialog${menu}`] = false;
    },

    showAlertDel(){
      if (this.QUEUE_QNUM == "" || this.QUEUE_QNUM == undefined) {
        this.showAlert("선택된 큐가 없습니다.");
        return;
      }
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '정말 삭제하시겠습니까?',
        iconClass: 'svg-error-lg',
        type: 'confirm',
        callYes: this.callYes,
        callNo: this.callNo,
      })
    },

    callYes() {
      this.$store.commit("alertStore/openAlertDialog", {
      alertDialogToggle: false,
      });
      this.deleteQ();
    },

    callNo(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    success(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '정상처리 되었습니다.',
        iconClass: 'svg-done-lg',
        type: 'default'
      })
    },

    fail(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.',
        iconClass: 'svg-error-lg',
        type: 'default'
      })
    },

    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },
  },
  computed: {},
  watch: {
    gridNewDataText(){
      this.ASP_QCUST_KEY = this.gridNewDataText.ASP_CUST_INFO;
      this.COMPANY_QNM = this.gridNewDataText.COMPANY_NM;
      this.PHONE_QNUM = this.gridNewDataText.PHONE_NUM;
      this.selectQList();
    }
  }
};
</script>

<style></style>

<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">기업계정관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
         <div>
           <div>
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  hide-details
                  class="form-inp icon-calendar ml-2"
                  append-icon="svg-calendar"
                  label="조회기간"
                ></v-text-field>
              </template>
              <div class="datepicker-range">
                <v-date-picker
                  v-model="dates[0]"
                  no-title
                  dark
                  color="#F7AFAE"
                  @change="startDate"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
                ></v-date-picker>
                <v-date-picker
                  v-model="dates[1]"
                  dark
                  color="#F7AFAE"
                  no-title
                  show-current="false"
                  :min="dates[0]"
                  @change="endDate"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
                ></v-date-picker>
              </div>
              <div class="text-center pt-3 pb-3">
                <v-btn
                  outlined
                  class="btn-default"
                  @click="menu1 = false"
                  >확인</v-btn
                >
              </div>
            </v-menu>
            <v-text-field
              class="form-inp sm ml-2"
              name="name" label="고객사명" outlined hide-details
              v-model="ASP_NEWNM">
            </v-text-field>
           </div>
         </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" v-on:click="selectList"> 조회 </v-btn>
        </div>
       </div>
    </div>
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">고객사목록
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnBotalk')" outlined class="btn-default" v-on:click="botalk">봇톡스</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnRegiQ')" outlined class="btn-default ml-2" v-on:click="[showDialog('M810706P01')]">큐등록</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew')" outlined class="btn-default ml-2" v-on:click="showDialog('M810706P02')">신규</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDetail')" outlined class="btn-default ml-2" v-on:click="[chkTime(),showDialog('M810706P03')]">상세</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined class="btn-etc ml-2" v-on:click="showAlertDel">삭제</v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table
          dense
          height="513px"
          :headers="gridDataHeaders"
          :items="gridDataText"
          :items-per-page="30"
          single-select
          item-key="index"
          :page.sync="page"
          hide-default-footer
          class="grid-default"
          calculate-widths
          @page-count="pageCount = $event"
          @click:row="rowClick"
          @dblclick:row="[chkTime(),showDialog('M810706P03')]"
          no-data-text="검색된 기업이 없습니다."
          :item-class="isActiveRow"
        >
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
        <p class="caption text-right">
          <strong class="clr-error">{{ gridDataText.length }}</strong
          >건의 기업이 검색되었습니다.
        </p>
      </div>
    </div>

    <!-- 봇톡스 등록 -->
    <form ref="frmBottalks" name="frmBottalks" method="post">
        <input type="hidden" ref="token" name="token" value="" />
    </form>
    <!-- 봇톡스 등록 -->

    <!-- 큐등록 모달창 -->
    <div class="">
      <v-dialog
        max-width="1400"
        max-height="1200"
        v-model="DialogM810706P01"
        content-class="square-modal"
      >
        <dialog-M810706P01
            headerTitle="큐등록"
            @hide="hideDialog('M810706P01')"
            :gridNewDataText="gridNewDataText"
            @input="gridNewDataText = $event"
          >
          </dialog-M810706P01>
          <template v-slot:body>

          </template>
      </v-dialog>
    </div>
    <!-- 큐등록 모달창 -->

    <!-- 신규기업 등록 모달창 -->
    <div class="">
      <v-dialog
        max-width="1400"
        max-height="1200"
        v-model="DialogM810706P02"
        content-class="square-modal min-auto"
      >
        <dialog-M810706P02
            headerTitle="신규기업 등록"
            @hide="hideDialog('M810706P02')"
            @Qsave="selectList(),hideDialog('M810706P02')"
          >
          </dialog-M810706P02>
          <template v-slot:body>

          </template>
      </v-dialog>
    </div>
    <!-- 신규기업 등록 모달창 -->

    <!-- 기업 상세 모달창 -->
    <div class="">
      <v-dialog
        max-width="1400"
        max-height="1200"
        v-model="DialogM810706P03"
        content-class="square-modal min-auto"
      >
        <dialog-M810706P03
            headerTitle="기업 상세"
            @hide="hideDialog('M810706P03')"
            :detailAsp="detailAsp"
            @input="detailAsp = $event"
            @Qsave="selectList(),hideDialog('M810706P03')"
          >
          </dialog-M810706P03>
          <template v-slot:body>

          </template>
      </v-dialog>
    </div>
    <!-- 기업 상세 모달창 -->
  </div>
  </div>
</template>

<script>
import api from '../../store/apiUtil.js';
import { mixin } from "@/mixin/mixin.js";
import DialogM810706P01 from "./M810706P01.vue";
import DialogM810706P02 from "./M810706P02.vue";
import DialogM810706P03 from "./M810706P03.vue";

export default {
  name: "MENU_M810706", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    DialogM810706P01,
    DialogM810706P02,
    DialogM810706P03,
  },
  data() {
    return {
      menu1: false,
      DialogM810706P01: false,
      DialogM810706P02: false,
      DialogM810706P03: false,
      dropItemRemove: true,
      gridSelected:[],
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      gridDataHeaders: [
        {
          text: 'No',
          align: 'center',
          value: 'index',
          width: '50px',
          sortable: false,
        },
        {text:"고객사ID", value: 'ASP_CUST_INFO', align: 'center', sortable: false, },
        {text:"고객사명", value: 'COMPANY_NM', align: 'center', sortable: false, },
        {text:"전화번호", value: 'PHONE_NUM', align: 'center', sortable: false, },
        {text:"이메일주소", value: 'EMAIL', align: 'center', sortable: false, },
        {text:"홈페이지", value: 'HOMEPAGE_ADDR', align: 'center', sortable: false, },
        {text:"계정생성제한", value: 'ACCOUNT_CREATION_LIMIT', align: 'center', sortable: false, },
        {text:"상품명", value: "PRODUCT_NAME",align: 'center', sortable: false},
        {text:"서비스시작일시", value: "SERV_START_DTTM",align: 'center', sortable: false},
        {text:"서비스종료일시", value: "SERV_END_DTTM",align: 'center', sortable: false},
        {text:"서비스유지여부", value: 'SERV_KEEP_YN', align: 'center',sortable: false, },
        {text:"사업자등록증", value: "BUSI_REGIST",align: 'center', sortable: false},
      ],
      gridDataText: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      totalVisible: 10,

      ASP_NM:'',
      ASP_NEWNM:'',

      ASP_NEWCUST_KEY:'',
      COMPANY_NEWNM:'',
      PHONE_NEWNUM:'',
      QUEUE_NEWNUM:'',
      REGIST_NEWID:'',
      MODI_NEWTIME:'',

      NEWEMAIL:'',
      HOMEPAGE_NEWADDR:'',
      ACCOUNT_NEWCREATION_LIMIT:'',
      PRODUCT_NEWNAME:'',
      SERV_START_NEWDTTM:'',
      SERV_END_NEWDTTM:'',
      SERV_KEEP_NEWYN:'',
      BUSI_NEWREGIST:'',
      PRODUCT_NEWCD:'',
      DATA_NEWFLAG:'',

      QUEUE_REG_NUM: '',

      gridNewDataText:[],

      dialogText: false,

      detailAsp:{},

      selectedRows: [],

    };
  },

  created() {
  },

  methods: {
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    removeDropitem(index){
      this.dropItemM.splice(index, 1);
    },

    botalk(){
      api.post("api/setting/system/entrprs-acnt-manage/bot-setting",   //api url입력
      {
        PALETTE: 'palette',
        CERTIFY_KEY: 'e64185556227987574ef2cf232737a0b',
      },
      {head: {
        "SERVICE" : "setting.system.entrprs-acnt-manage",
        "METHOD" : "bot-setting",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        console.log(response.data.DATA);
        this.gridDataText = response.data.DATA;

        response.data.DATA[0];
        var RES_ERR = response.data.DATA[0].error;
        var RES_ACTION = response.data.DATA[0].action;
        var RES_URL = response.data.DATA[0].logurl;
        var RES_TOKEN = response.data.DATA[0].token;

        this.$refs.token.value = RES_TOKEN;
        if (RES_ACTION == "login"){

	        //ID가 기존에 있는지 체크한다.
	        if(typeof(tempTalkView) == "undefined" || tempTalkView == "") {

            window.open("", "bottalksLog");
            var dw = document.frmBottalks;
            dw.action = RES_URL;
            dw.target = "bottalksLog";
            dw.submit();

          }
        } else if (RES_ACTION == "userinfo"){
          alert("봇톡스 신규등록 성공!");
        }

        this.selectList();
      })
      .catch((err) => {
        alert(err);
      })
    },

    btnRegistQ(){
      api.post("api/setting/system/queue/regist",   //api url입력
      {
        ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
        COMPANY_NM: this.COMPANY_NEWNM,
        PHONE_NUM: this.PHONE_NEWNUM,
        QUEUE_NUM: this.QUEUE_REG_NUM,
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "setting.system.queue",
        "METHOD" : "regist",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        this.selectQList();
      })
      .catch((err) => {
        alert(err);
      })
    },

    selectQList(){
      api.post("api/setting/system/entrprs-acnt-manage/queue/selectlist",   //api url입력
      {
        ASP_CUST_INFO: this.ASP_NEWCUST_KEY,
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "setting.system.entrprs-acnt-manage.queue",
        "METHOD" : "selectlist",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        console.log(response.data.DATA);
        this.gridNewDataText = response.data.DATA;
      })
      .catch((err) => {
        alert(err);
      })
    },

    showDialog(menu) {
      if(menu == 'M810706P01'){
        if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
          this.showAlert("선택된 고객사가 없습니다.");
          return;
        }
      }
      if(menu == 'M810706P03'){
        if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
          this.showAlert("선택된 고객사가 없습니다.");
          return;
        }
      }
      this[`Dialog${menu}`] = true;
    },
    hideDialog(menu) {
      this[`Dialog${menu}`] = false;
    },

    deleteAsp(){
      api.post("api/setting/system/entrprs-acnt-manage/delete",   //api url입력
      {
        ASP_CUST_INFO: this.ASP_NEWCUST_KEY,
        COMPANY_NM: this.COMPANY_NEWNM,
        PHONE_NUM: this.PHONE_NEWNUM,
        EMAIL: this.NEWEMAIL,
        HOMEPAGE_ADDR: this.HOMEPAGE_NEWADDR,
        ACCOUNT_CREATION_LIMIT: this.ACCOUNT_NEWCREATION_LIMIT,
        PRODUCT_NAME: this.PRODUCT_NEWNAME,
        SERV_START_DTTM: this.SERV_START_NEWDTTM,
        SERV_END_DTTM: this.SERV_END_NEWDTTM,
        SERV_KEEP_YN: this.SERV_KEEP_NEWYN,
        BUSI_REGIST: this.BUSI_NEWREGIST,
        PRODUCT_CD: this.PRODUCT_NEWCD,
        DATA_FLAG: 'N',
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "setting.system.entrprs-acnt-manage",
        "METHOD" : "delete",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        this.selectList()
        this.success();
      })
      .catch((err) => {
        this.fail();
      })
    },

    selectList(){
      let DT1 = this.dates[0];
      let DT1_Array = DT1.split("-");
      var YEAR1 = DT1_Array[0];
      var MONTH1 = DT1_Array[0] + DT1_Array[1];
      var ST_DATE = DT1_Array[0] + DT1_Array[1] + DT1_Array[2];

      let DT2 = this.dates[1];
      let DT2_Array = DT2.split("-");
      var MONTH2 = DT2_Array[0] + DT2_Array[1];
      var END_DATE = DT2_Array[0] + DT2_Array[1] + DT2_Array[2];

      api.post("api/setting/system/entrprs-acnt-manage/list",   //api url입력
      {
        REG_DTTM_FROM: ST_DATE,
        REG_DTTM_TO: END_DATE,
        SEARCH_COMPANY_NM: this.ASP_NEWNM,
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "setting.system.entrprs-acnt-manage",
        "METHOD" : "list",
        "TYPE" : "BIZ_SERVICE",
        "ROW_CNT" : "200",
        "PAGES_CNT" : "1",
      }})
      .then((response) => {
        console.log(response.data.DATA);
        this.gridDataText = response.data.DATA;
        for(var i=0; i<this.gridDataText.length;i++){
          this.gridDataText[i]["index"]= i+1;
        }
        this.page = 1;
      })
      .catch((err) => {
        alert(err);
      })
    },

    //Table row 클릭이벤트
    rowClick(item, row) {

      this.gridSelected = item;
      this.detailAsp = item;
      this.gridNewDataText = item;

      this.ASP_NEWCUST_KEY = item.ASP_CUST_INFO;
      this.COMPANY_NEWNM = item.COMPANY_NM;
      this.PHONE_NEWNUM = item.PHONE_NUM;
      this.NEWEMAIL = item.EMAIL;
      this.HOMEPAGE_NEWADDR = item.HOMEPAGE_ADDR;
      this.ACCOUNT_NEWCREATION_LIMIT = item.ACCOUNT_CREATION_LIMIT;
      this.PRODUCT_NEWNAME = item.PRODUCT_NAME;
      this.SERV_START_NEWDTTM = item.SERV_START_DTTM;
      this.SERV_END_NEWDTTM = item.SERV_END_DTTM;
      this.SERV_KEEP_NEWYN = item.SERV_KEEP_YN;
      this.BUSI_NEWREGIST = item.BUSI_REGIST;
      this.PRODUCT_NEWCD = item.PRODUCT_CD;

      this.chkTime();
    },

    isActiveRow(item){
      const activeClass = item.ASP_CUST_INFO === this.ASP_NEWCUST_KEY ? 'active' : '';
      return activeClass;
    },

    chkTime(){
      let DATE_TIME1 = this.SERV_START_NEWDTTM;
      let DT1_Array = DATE_TIME1.split(" ");
      var ST_DATE = DT1_Array[0];
      var ST_TM = DT1_Array[1]


      let DATE_TIME2 = this.SERV_END_NEWDTTM;
      let DT2_Array = DATE_TIME2.split(" ");
      var END_DATE = DT2_Array[0];
      var END_TM = DT2_Array[1]

      this.detailAsp.ST_DATE=ST_DATE;
      this.detailAsp.ST_TM=ST_TM;
      this.detailAsp.END_DATE=END_DATE;
      this.detailAsp.END_TM=END_TM;
    },

    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },

    showAlertDel(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '기업계정을 삭제하시겠습니까?',
        iconClass: 'svg-error-lg',
        type: 'confirm',
        callYes: this.callYes,
        callNo: this.callNo,
      })
    },

    callYes() {
      this.$store.commit("alertStore/openAlertDialog", {
      alertDialogToggle: false,
      });
      this.deleteAsp();
    },

    callNo(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    success(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '정상처리 되었습니다.',
        iconClass: 'svg-done-lg',
        type: 'default'
      })
    },

    fail(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.',
        iconClass: 'svg-error-lg',
        type: 'default'
      })
    },
  },

  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
  },

  watch: {
  }
};
</script>

<style></style>
